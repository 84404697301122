import React, { useRef, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import Servicecard from "../components/home/Servicecard";
import Filocard from "../components/home/Filocard";
import Filocardright from "../components/home/Filocardright";
import Header from "../components/core/Header";
import Footer from "../components/core/Footer";
import WhatsAppButton from "../components/home/WhatsAppButton";

import lineplane from "../assets/home/icon/lineplane.png";
import downarrow from "../assets/home/icon/downarrow.png";
import cloud from "../assets/home/icon/cloud.png";

import vafa from "../assets/core/logos/vafa.png";
import aoc from "../assets/core/logos/aoc.png";
import pic from "../assets/core/logos/pic.png";
import vizyontec from "../assets/core/logos/vizyontec.png";
import hangar from "../assets/core/icons/hangar.png";

import arrowcop from "../assets/home/fleet/arrowcop.png";
import cc11 from "../assets/home/fleet/cc11.png";
import cesna from "../assets/home/fleet/cesna.png";
import guimball from "../assets/home/fleet/guimball.png";
import piper from "../assets/home/fleet/piper.png";
import seminole from "../assets/home/fleet/seminole.png";
import tecnam from "../assets/home/fleet/tecnam.png";
import trixy from "../assets/home/fleet/ltrixy.png";
import popup1 from "../assets/popup/popup1.jpeg";
import popup2 from "../assets/popup/popup2.jpeg";


const PopUp = ({ handleClose }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [popup1, popup2];

  const handleImageClose = () => {
    if (currentImage < images.length - 1) {
      setCurrentImage(currentImage + 1);
    } else {
      handleClose();
    }
  };

  return (
    <div className="popup">
      <div className="popup-overlay" onClick={handleImageClose}></div>
      <div className="popup-content">
        <button className="close-button" onClick={handleImageClose}>
          X
        </button>
        <img
          className="popup-image"
          src={images[currentImage]}
          alt="Popup Image"
        />
      </div>
    </div> 
  );
}; 

function Home() {
  const { t } = useTranslation();

  const [isPopUpOpen, setPopUpOpen] = useState(true); // Otomatik olarak açık
  const handleClosePopUp = () => {
    setPopUpOpen(false);
  };

  const scrollRef = useRef(null);

  return (
    <div className="home">
      <div className="home-con">
        <Header />
        {isPopUpOpen && <PopUp handleClose={handleClosePopUp} />}
        <div className="home-entrance">
          <h1>"{t("home_entrance")}"</h1>
          <a href="#corp">
            <img src={downarrow} alt="aşağı ok" />
          </a>
        </div>
        <div className="home-corp" id="corp">
          <div className="home-line-plane">
            <hr />
            <motion.img
              viewport={{
                once: true,
              }}
              initial={{
                x: -100,
              }}
              whileInView={{
                x: 0,
              }}
              transition={{
                type: "just",
                duration: 1,
                delay: 0.2,
              }}
              src={lineplane}
              alt=""
            />
          </div>
          <h1 id="corp"> {t("corprate")} </h1>
          <div className="corp-outer-con">
            <div className="corp-exp-con">
              <motion.div
                viewport={{
                  once: true,
                }}
                initial={{
                  y: -20,
                  opacity: 0.2,
                }}
                whileInView={{
                  y: 0,
                  opacity: 1,
                }}
                transition={{
                  duration: 1,
                  type: "just",
                  delay: 0.2,
                }}
                className="corp-card top"
              >
                <div className="corp-card-exp">
                  <h1> {t("about_us")} </h1>
                  <p>{t("about_us_exp")}</p>
                </div>
                <Link to="/aboutus">
                  <div className="corp-card-button">
                    <p>{t("continue_reading")}</p>
                  </div>
                </Link>
              </motion.div>
              <motion.div
                viewport={{
                  once: true,
                }}
                initial={{
                  x: -20,
                  opacity: 0.2,
                }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                }}
                transition={{
                  duration: 1,
                  type: "just",
                  delay: 0.2,
                }}
                className="corp-card left"
              >
                <div className="corp-card-exp">
                  <h1> {t("vision")} </h1>
                  <p> {t("vision_exp")} </p>
                </div>
                <Link to="/vision">
                  <div className="corp-card-button">
                    <p>{t("continue_reading")}</p>
                  </div>
                </Link>
              </motion.div>
              <motion.div
                className="corp-card right"
                viewport={{
                  once: true,
                }}
                initial={{
                  x: 20,
                  opacity: 0.2,
                }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                }}
                transition={{
                  duration: 1,
                  type: "just",
                  delay: 0.2,
                }}
              >
                <div className="corp-card-exp">
                  <h1> {t("why_us")} </h1>
                  <p> {t("why_us_exp")} </p>
                </div>
                <Link to="/whyus">
                  <div className="corp-card-button">
                    <p>{t("continue_reading")}</p>
                  </div>
                </Link>
              </motion.div>
              <motion.div
                className="corp-card bottom"
                viewport={{
                  once: true,
                }}
                initial={{
                  y: 20,
                  opacity: 0.2,
                }}
                whileInView={{
                  y: 0,
                  opacity: 1,
                }}
                transition={{
                  duration: 1,
                  type: "just",
                  delay: 0.2,
                }}
              >
                <div className="corp-card-exp">
                  <h1> {t("director")} </h1>
                  <p> {t("director_exp")} </p>
                </div>
                <Link to="/director">
                  <div className="corp-card-button">
                    <p>{t("continue_reading")}</p>
                  </div>
                </Link>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="home-service" id="service">
          <div className="home-line-cloud">
            <hr />
            <motion.img
              src={cloud}
              alt=""
              viewport={{
                once: true,
              }}
              initial={{
                y: 20,
              }}
              whileInView={{
                y: 0,
              }}
              transition={{
                type: "just",
                delay: 0.2,
                duration: 1,
              }}
            />
          </div>
          <div className="home-service-con">
            <h1>{t("services")}</h1>
            <motion.div
              className="home-service-top"
              viewport={{
                once: true,
              }}
              initial={{
                y: 30,
                opacity: 0.5,
              }}
              whileInView={{
                y: 0,
                opacity: 1,
              }}
              transition={{
                type: "just",
                delay: 0.5,
                duration: 1.5,
              }}
            >
              <Servicecard link="vafa" img={vafa} title="Vafa" />
              <Servicecard link="aoc" img={aoc} title={t("aoc_service")} />
              <Servicecard link="pic" img={pic} title="Pic Club" />
            </motion.div>
            <motion.div
              className="home-service-top"
              viewport={{
                once: true,
              }}
              initial={{
                y: 30,
                opacity: 0.5,
              }}
              whileInView={{
                y: 0,
                opacity: 1,
              }}
              transition={{
                type: "just",
                delay: 0.5,
                duration: 1.5,
              }}
            >
              <Servicecard
                link="maintenance"
                img={vizyontec}
                title={t("maintenance")}
              />
              <Servicecard
                link="hangar"
                img={hangar}
                title={t("hangarization")}
              />
            </motion.div>
          </div>
        </div>
        <div className="home-filo" id="fleet">
          <div className="home-line-plane">
            <hr />
            <motion.img
              src={lineplane}
              alt=""
              viewport={{
                once: true,
              }}
              initial={{
                x: -100,
              }}
              whileInView={{
                x: 0,
              }}
              transition={{
                type: "just",
                duration: 1,
                delay: 0.2,
              }}
            />
            <motion.img
              className="cloud"
              src={cloud}
              alt=""
              viewport={{
                once: true,
              }}
              initial={{
                y: 20,
              }}
              whileInView={{
                y: 0,
              }}
              transition={{
                type: "just",
                delay: 0.2,
                duration: 1,
              }}
            />
          </div>
          <div className="home-filo-con">
            <h1> {t("fleet")} </h1>
            <div className="home-filo-main">
              <div className="home-filo-left">
                <h5> {t("fly_fleet")} </h5>
                <Filocard
                  link="/cesna"
                  img={cesna}
                  model="cessna 172 skyhawk"
                />

                <Filocard
                  link="/piper"
                  img={piper}
                  model="Piper Arrow pA28-R"
                />
              </div>
              <div className="home-filo-right">
                <h5> {t("traning_fleet")} </h5>
                <Filocardright
                  link="/trixy"
                  img={trixy}
                  model="uL gyrocopter trixy"
                />
                <Filocardright
                  link="/tecnam"
                  img={tecnam}
                  model="tecnam p2008JC"
                />
                <Filocardright
                  link="/seminole"
                  img={seminole}
                  model="pİPER SEMİNOLE PA44"
                />
                <Filocardright
                  link="/arrow"
                  img={arrowcop}
                  model="arrow copter xC20 "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="home-blog">
          <div className="home-line-plane">
            {" "}
            <hr />
            <motion.img
              src={lineplane}
              alt=""
              viewport={{
                once: true,
              }}
              initial={{
                x: -100,
              }}
              whileInView={{
                x: 0,
              }}
              transition={{
                type: "just",
                duration: 1,
                delay: 0.2,
              }}
            />
            <motionimg
              className="cloud"
              src={cloud}
              alt=""
              viewport={{
                once: true,
              }}
              initial={{
                y: 20,
              }}
              whileInView={{
                y: 0,
              }}
              transition={{
                type: "just",
                delay: 0.2,
                duration: 1,
              }}
            />
          </div>
          <div className="home-blog-main">
            <div className="home-blog-main-header">
              <h1>Blog</h1>
            </div>
            <div className="home-blog-bottom">
              <div className="home-blog-exp">
                <h3> {t("blog_header2")} </h3>
                <hr />
                <h3> {t("blog_header3")} </h3>
              </div>
              <Link to="/blog">
                <div className="home-blog-button">
                  <h3> {t("blog_button")} </h3>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <WhatsAppButton />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
