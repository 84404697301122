import React from "react";
import { useTranslation } from "react-i18next";

import Blogcard from "../../components/Blog/Blogcard";

import blog2 from "../../assets/blog/img/blog2.png";
import blog3 from "../../assets/blog/img/blog3.png";
import Header from "../../components/core/Header";
import WhatsAppButton from "../../components/home/WhatsAppButton";

function Bloghome() {
  const { t } = useTranslation();
  return (
    <div className="blog-home">
      <Header />
      <div className="blog-home-main">
        <div className="blog-home-cards">
        <Blogcard
            img={blog3}
            head={t("blog_header3")}
            cover={t("blog_exp3")}
            date={"5 " + t("september")}
            editor="Vizyon Havacılık"
            link="/blogthree"
          />
          <hr />
          <Blogcard
            img={blog2}
            head={t("blog_header2")}
            cover={t("blog_exp2")}
            date={"9 " + t("march")}
            editor="Vizyon Havacılık"
            link="/blogtwo"
          />
        </div>
      </div>
      {/* <div className="blog-home-main">
        <div className="blog-home-cards">
          aşşağı eklersek burası açılacak
        </div>
      </div> */}
      <WhatsAppButton />
    </div>
    
  );
}

export default Bloghome;
