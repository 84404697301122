import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import Home from './pages/Home';
import Aboutus from "./pages/corp/Aboutus";
import Whyus from './pages/corp/Whyus';
import Vision from './pages/corp/Vision';
import Director from './pages/corp/Director';
import Vafa from './pages/services/Vafa';
import Aoc from './pages/services/Aoc';
import Pic from './pages/services/Pic';
import Maintenance from './pages/services/Maintenance';
import Hangar from './pages/services/Hangar';
import Piper from './pages/fleet/Piper';
import Arrow from './pages/fleet/Arrow';
import Tecnam from './pages/fleet/Tecnam';
import Cesna from './pages/fleet/Cesna';
import Guimball from './pages/fleet/Guimball';
import Trixy from './pages/fleet/Trixy';
import Seminole from './pages/fleet/Seminole';
import Carbon from './pages/fleet/Cc';
import Bloghome from './pages/blog/Bloghome';
import Blogtwo from './pages/blog/Blogtwo';
import Blogthree from './pages/blog/Blogthree';
import Graduates from './pages/Gaduates';

import './Style.scss';

function App() {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/aboutus' element={<Aboutus />} />
        <Route path='/whyus' element={<Whyus />} />
        <Route path='/vision' element={<Vision />} />
        <Route path='/director' element={<Director />} />
        <Route path='/vafa' element={<Vafa />} />
        <Route path='/aoc' element={<Aoc />} />
        <Route path='/pic' element={<Pic />} />
        <Route path='/maintenance' element={<Maintenance />} />
        <Route path='/hangar' element={<Hangar />} />
        <Route path='/piper' element={<Piper />} />
        <Route path='/arrow' element={<Arrow />} />
        <Route path='/tecnam' element={<Tecnam />} />
        <Route path='/cesna' element={<Cesna />} />
        <Route path='/guimball' element={<Guimball />} />
        <Route path='/trixy' element={<Trixy />} />
        <Route path='/seminole' element={<Seminole />} />
        <Route path='/carbon' element={<Carbon />} />
        <Route path='/blog' element={<Bloghome />} />
        <Route path='/blogtwo' element={<Blogtwo />} />
        <Route path='/blogthree' element={<Blogthree />} />
        <Route path="/Graduates" element={<Graduates />} />
      </Routes>
    </div>
  );
}

export default App;
